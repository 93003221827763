import React from 'react';
import RegisterForm from './register-form';

export interface RegisterProps {
    privacyPolicy: string;
    individualDescriptionMarkdown: string;
    businessDescriptionMarkdown: string;
    loginRedirectContent: string;
    loginRedirectButtonText: string;
}

const Register: React.FC<RegisterProps> = ({
    privacyPolicy,
    individualDescriptionMarkdown,
    businessDescriptionMarkdown,
    loginRedirectButtonText,
    loginRedirectContent
}) => {
    return (
        <div className="bg-checkout-bg px-16  pb-20 pt-14 lg:pb-150 lg:pt-60">
            <div className="m-auto grid max-w-[530px] bg-white p-20 lg:p-30">
                <RegisterForm
                    privacyPolicy={privacyPolicy}
                    individualDescription={individualDescriptionMarkdown}
                    businessDescription={businessDescriptionMarkdown}
                    loginRedirectContent={loginRedirectContent}
                    loginRedirectButtonText={loginRedirectButtonText}
                />
            </div>
        </div>
    );
};

export default Register;
