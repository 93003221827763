import type { FC } from 'react';
import React, { useState, useMemo, useEffect } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useFormat } from 'helpers/hooks/useFormat';
import Button from 'components/commercetools-ui/atoms/button';
import Redirect from 'helpers/redirect';
import { useAccount } from 'frontastic';
import AccountFieldsForm, { AccountFieldsFormType } from '../../account/account-atoms/account-fields-form';
import Modal from 'components/commercetools-ui/atoms/modal';
import Markdown from '../../markdown';

interface Props {
    privacyPolicy?: string;
    individualDescription?: string;
    businessDescription?: string;
    loginRedirectContent: string;
    loginRedirectButtonText: string;
}

const RegisterForm: FC<Props> = ({
    privacyPolicy,
    individualDescription,
    businessDescription,
    loginRedirectContent,
    loginRedirectButtonText
}) => {
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const title = formatAccountMessage({ id: 'personal.details', defaultMessage: 'Personal details' });

    //account actions
    const { loggedIn, redirectToLogin } = useAccount();

    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        if (redirectToLogin) {
            setIsOpen(redirectToLogin);
        }
    }, [redirectToLogin]);

    const router = useRouter();

    const closeRedirectModal = () => {
        setIsOpen(false);
        router.replace('/login');
    };

    //form submission

    const searchParams = useSearchParams();
    const lvp = searchParams.get('lvp');

    const redirectLink = useMemo(() => {
        const lastVisitedPage = lvp ? `/${lvp}` : '/account';
        return lastVisitedPage;
    }, [lvp]);

    if (loggedIn) return <Redirect target={redirectLink} />;

    return (
        <>
            <h1 className="text-18">{formatAccountMessage({ id: 'become.member', defaultMessage: 'Create account' })}</h1>
            <AccountFieldsForm
                type={AccountFieldsFormType.REGISTER}
                privacyPolicy={privacyPolicy}
                title={title}
                individualDescription={individualDescription}
                businessDescription={businessDescription}
                containerClassName="grid gap-12"
            />

            <Modal
                isOpen={isOpen}
                className="relative w-[90%] rounded-md bg-white"
                style={{ content: { maxWidth: '545px' } }}
                closeTimeoutMS={200}
            >
                <div className="flex flex-col gap-[25px] p-[15px] text-base leading-6 md:gap-20 md:p-40">
                    <Markdown markdown={loginRedirectContent} className="markdown" />
                    <Button
                        data-cy="close-membership-modal"
                        variant="secondary"
                        size="full"
                        onClick={closeRedirectModal}
                        className="rounded-md md:rounded-lg"
                    >
                        {loginRedirectButtonText}
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default RegisterForm;
