'use client';

import type { GetAccountActionReturn } from '@commercetools/frontend-composable-commerce/lib/types/actions/AccountActions';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { CurrencyMapperThreshold } from '@wilm/shared-types/base/Settings';
import type { GoogleSettings } from '@wilm/shared-types/google/Settings';
import type { SessionSettingsResponseType } from '@wilm/shared-types/session/Session';
import type { BrandSettings } from '@wilm/shared-types/settings/BrandSettings';
import Toaster from 'components/commercetools-ui/atoms/toaster';
import i18nConfig from 'i18n.config';
import { sdk } from 'sdk';
import type { Translations } from 'types/i18n';
import BrandSettingsProvider from './brand-settings';
import BlacklistedCountriesProvider from './countries';
import CurrencyMapperThresholdProvider from './currency-mapper-threshold';
import GoogleSettingsProvider from './google-settings';
import I18nProvider from './i18n';
import SessionProvider from './session';
import { SWRProvider } from './swr';

interface ProvidersProps {
    blacklistedCountries: string[];
    translations: Translations;
    accountResult?: SDKResponse<GetAccountActionReturn>;
    brandSettings: BrandSettings;
    googleSettings: GoogleSettings;
    currencyMapperThreshold: CurrencyMapperThreshold;
    sessionSettings: SessionSettingsResponseType;
}

export const Providers = ({
    translations,
    accountResult,
    blacklistedCountries,
    brandSettings,
    googleSettings,
    currencyMapperThreshold,
    sessionSettings,
    children
}: React.PropsWithChildren<ProvidersProps>) => {
    const locale = i18nConfig.defaultLocale;

    sdk.configureForNext(locale);
    return (
        <SessionProvider sessionSettings={sessionSettings}>
            <I18nProvider translations={translations}>
                <SWRProvider value={{ fallback: { '/action/account/getAccount': accountResult } }}>
                    <BrandSettingsProvider brandSettings={brandSettings}>
                        <GoogleSettingsProvider googleSettings={googleSettings}>
                            <BlacklistedCountriesProvider blacklistedCountries={blacklistedCountries}>
                                <CurrencyMapperThresholdProvider currencyMapperThreshold={currencyMapperThreshold}>
                                    {children}
                                    <Toaster />
                                </CurrencyMapperThresholdProvider>
                            </BlacklistedCountriesProvider>
                        </GoogleSettingsProvider>
                    </BrandSettingsProvider>
                </SWRProvider>
            </I18nProvider>
        </SessionProvider>
    );
};
